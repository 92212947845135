import Vue from "vue";
import {
  mdiCashPlus,
  mdiPlus,
  mdiCancel,
  mdiCurrencyUsd,
  mdiCash,
  mdiShieldAccount,
  mdiMonitorDashboard,
  mdiClose,
} from "@mdi/js";
import {
  OrdenDotacion,
  DetalleOrdenDotacion,
  Kiosko,
  KioskoDispensador,
  EstatusDetalleOrdenDotacion,
  EstatusOrdenDotacion,
} from "apd.sistemapagos.models";
import { User } from "apd.intranet.models";
import spinnerModule from "@/store/modules/spinnerModule";
import errorSnackbarModule from "@/store/modules/snackbarModule";
import { Guid } from "guid-typescript";
import { formatISO } from "date-fns";
import CommonDataModule from "@/store/modules/commonDataModule";
import intranetModule from "@/store/modules/intranetModule";
import dotacionesModule from "@/store/modules/dotacionesModule";
import { AxiosHttpClient, ApiSistemaPagos, ApiErrorResponse } from "apd.apiconnectors";
import { ApiList, getUrl } from "@/config/apiUrls";

export default Vue.extend({
  name: "NuevaOrdenDotacionComponent",
  components: {
    detalleDotacion: () => import("@/components/dotaciones/detalleDotacion/detalleDotacion.vue"),
  },
  data() {
    return {
      Icons: {
        Dotaciones: mdiCashPlus,
        Agregar: mdiPlus,
        Eliminar: mdiCancel,
        Cantidad: mdiCashPlus,
        Denominacion: mdiCurrencyUsd,
        TipoDenominacion: mdiCash,
        Custodio: mdiShieldAccount,
        Cajero: mdiMonitorDashboard,
        Close: mdiClose,
      } as Record<string, string>,
      ordenDotacion: {} as OrdenDotacion,
      detalle: [] as DetalleOrdenDotacion[],
      billete: 0,
      moneda: 1,
      cajeros: [] as Kiosko[],
      usuarios: [] as User[],
      dotacionEstandarSwitch: false as boolean,
      dispensador: [] as KioskoDispensador[],
      dialog: false,
      dotacionActiva: false,
      dotaciones: [] as OrdenDotacion[],
    };
  },
  computed: {
    checkValueGuardar(): boolean {
      return this.ordenDotacion.idUsuarioEjecutor != undefined &&
        this.ordenDotacion.idKiosko != undefined &&
        this.total > 0 &&
        this.ordenDotacion.idUsuarioEjecutor != "" &&
        this.ordenDotacion.idKiosko != ""
        ? false
        : true;
    },
    checkDisabledDotacion(): boolean {
      return this.ordenDotacion.idKiosko != undefined && this.ordenDotacion.idKiosko != "" ? false : true;
    },
    idDotacion() {
      return Guid.create().toString();
    },
    cajerosList(): Kiosko[] {
      return CommonDataModule.Cajeros.filter((e) => e.tipoKiosko != 1 && !e.nombre.includes("CajaGeneral")).sort(
        (a, b) => (a.nombrePublico > b.nombrePublico ? 1 : -1),
      );
    },
    usuariosList(): User[] {
      return intranetModule.usuarios
        .filter((e) => e.type == 0 || e.type == 3)
        .sort((a, b) => (a.givenName > b.givenName ? 1 : -1));
    },
    total(): number {
      return this.detalle.reduce((r, i) => r + Number(i.cantidad * i.denominacion), 0);
    },
  },
  methods: {
    async load() {
      spinnerModule.Show();
      await CommonDataModule.LoadCajeros();
      await intranetModule.LoadUsuarios();
      spinnerModule.Hide();
      this.dialog = true;
    },
    async getDotacionEstandar() {
      await dotacionesModule.LOAD_DISPENSADOR(this.ordenDotacion.idKiosko);
      this.dispensador = dotacionesModule.dispensador;
      this.detalle = [];

      if (this.dotacionEstandarSwitch == true) {
        if (this.dispensador.length > 0) {
          spinnerModule.Show();
          this.dispensador.forEach((i) => {
            this.detalle.push({
              id: Guid.create().toString(),
              created: formatISO(Date.now()),
              updated: formatISO(Date.now()),
              idOrdenDotacion: this.idDotacion,
              estatusDetalleOrdenDotacion: EstatusDetalleOrdenDotacion.Nueva,
              observaciones: undefined,
              cantidad: i.limite,
              denominacion: i.denominacion,
              tipoDenominacion: i.tipoDenominacion,
            });
          });
          spinnerModule.Hide();
        } else {
          this.dotacionEstandarSwitch = false;
        }
      } else {
        this.detalle = [];
      }
    },
    vaciar(value: string) {
      this.dotacionEstandarSwitch = false;
      this.detalle = [];
      if (value == "cerrar") {
        this.ordenDotacion.idKiosko = "";
        this.ordenDotacion.idUsuarioEjecutor = "";
        this.dialog = false;
      }
      dotacionesModule.LOAD_DOTACION_USUARIO;
    },
    add() {
      this.detalle.push({
        id: Guid.create().toString(),
        idOrdenDotacion: this.idDotacion,
        estatusDetalleOrdenDotacion: EstatusDetalleOrdenDotacion.Nueva,
        observaciones: undefined,
        cantidad: 0,
        denominacion: 0,
        tipoDenominacion: 0,
        created: formatISO(Date.now()),
        updated: formatISO(Date.now()),
      });
    },
    remove(index: number) {
      this.detalle.splice(index, 1);
    },
    async guardar() {
      (this.ordenDotacion.id = this.idDotacion),
        (this.ordenDotacion.idUsuarioCreador = this.$store.getters["oidcStore/oidcUser"].sub),
        (this.ordenDotacion.estatusOrdenDotacion = EstatusOrdenDotacion.Nueva),
        (this.ordenDotacion.detallesOrdenDotacion = this.detalle),
        (this.ordenDotacion.created = formatISO(Date.now())),
        (this.ordenDotacion.updated = formatISO(Date.now())),
        await dotacionesModule.LoadDotacionKiosko(this.ordenDotacion.idKiosko);
      this.dotaciones = dotacionesModule.dotacionesKiosko.filter(
        (e) => e.estatusOrdenDotacion == EstatusOrdenDotacion.Nueva && e.idKiosko == this.ordenDotacion.idKiosko,
      );
      if (this.dotaciones.length > 0) {
        this.dotacionActiva = true;
      } else {
        this.crearOrdenDotacion();
      }
    },
    async crearOrdenDotacion() {
      const clienteSP = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.$store.getters.appMode));
      clienteSP.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
      const apiSP = new ApiSistemaPagos(clienteSP);
      apiSP
        .RegistrarOrdenDotacion(this.ordenDotacion)
        .then((e) => {
          if (e == true) {
            errorSnackbarModule.ShowSuccess("Orden dotación creada");
            this.ordenDotacion.idKiosko = "";
            this.detalle = [];
            this.dialog = false;
          }
        })
        .catch((error) => {
          const x = error as ApiErrorResponse;
          errorSnackbarModule.Show(x.apiError.mensajeUsuario);
        })
        .finally(() => {
          spinnerModule.Hide;
        });
    },
  },
});
